import { untilDestroyed } from '@ngneat/until-destroy';
import {
  ReplaySubject,
  combineLatest,
  firstValueFrom,
  map,
  shareReplay,
  startWith,
  withLatestFrom,
} from 'rxjs';
import { arrayFilterAll, getNestedObjectData } from 'src/app/utilities';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  MAT_SELECT_CONFIG,
  MatSelect,
  MatSelectConfig,
} from '@angular/material/select';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';

@Component({
  selector:
    'alleva-input-dropdown-autocomplete[displayKey][formControl][label][list]',
  templateUrl: './input-dropdown-autocomplete.component.html',
  styleUrls: ['./input-dropdown-autocomplete.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        hideSingleSelectionIndicator: false,
      } satisfies MatSelectConfig,
    },
  ],
})
export class InputDropdownAutocompleteComponent<T extends object>
  extends CustomInputDirective<T>
  implements OnDestroy, OnInit, AfterViewInit
{
  public constructor(
    @Optional() @Self() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly overlayContainer: OverlayContainer,
  ) {
    super(ngControl, changeDetectorRef, elementRef);
  }

  @ViewChild('searchInput', { static: false })
  public searchInput!: ElementRef<HTMLInputElement>;

  protected isArray = Array.isArray;
  protected searchValue = '';

  /**
   * Whether the filtering should be done locally. When `true` the filter in the
   * dropdown will filter the list locally. When `false` the filter will emit
   * the value to the parent component to handle the filtering.
   */
  @Input() public isLocalFilter = true;

  /**
   * Whether the dropdown should retain the initial value even when it is not
   * existent in the list.
   *
   * Notes: This can be used to check if the value still exists in the list on
   * initialize and if it doesn't, add it back to the list. This is useful for
   * handling cases where the value was deleted and the dropdown can no longer
   * find the value matching the selected value. Essentially handling bad data.
   */
  @Input() public set retainInitialValue(value: BooleanInput) {
    this.#retainInitialValue = coerceBooleanProperty(value);
  }
  public get retainInitialValue(): boolean {
    return this.#retainInitialValue;
  }
  #retainInitialValue = false;

  private readonly initialValueSubject = new ReplaySubject<T | null>(1);

  /** Emits the filter value when it changes. */
  @Output() public readonly filterValueChange = new EventEmitter<
    string | null
  >();

  /**
   * The list of items to display in the dropdown.
   *
   * @required This is a required input.
   */
  @Input() public set list(values: readonly T[] | null | undefined) {
    if (!values) {
      return;
    }
    this.listSubject.next(values);
  }

  /** The maxiumum number of options that can be selected. */
  @Input() public override max: number | undefined;

  protected get isOverSelectionLimit(): boolean {
    if (this.max === undefined) {
      return false;
    }
    return this.matSelect?.value?.length > this.max;
  }

  /**
   * Whether the user should be allowed to select multiple options.
   */
  @Input() public set multiple(value: BooleanInput) {
    this.#multiple = coerceBooleanProperty(value);
  }
  public get multiple(): boolean {
    return this.#multiple;
  }
  #multiple = false;

  private readonly listSubject = new ReplaySubject<readonly T[]>(1);
  protected readonly listChanges = combineLatest([
    this.listSubject,
    this.initialValueSubject,
  ]).pipe(
    map(([list, initialValue]): readonly T[] => {
      const currentValue = this.baseControl.value;

      if (this.multiple && Array.isArray(currentValue)) {
        // Multi-value handling
        const missingValues = currentValue.filter(
          (value) => !list.some((item) => this.compareWith(item, value)),
        );
        if (missingValues.length > 0) {
          return [...missingValues, ...list];
        }
      } else {
        // Single value handling
        const isCurrentValueInitialValue = initialValue
          ? initialValue === currentValue
          : false;

        if (!this.retainInitialValue && isCurrentValueInitialValue) {
          return list;
        }

        const listHasCurrentValue =
          currentValue &&
          list.some((item) => this.compareWith(item, currentValue));

        if (!listHasCurrentValue && currentValue) {
          return [currentValue, ...list];
        }
      }

      return list;
    }),
    shareReplay(1),
  );

  private readonly localFilteredListSubject = new ReplaySubject<
    readonly T[] | null
  >(1);
  protected readonly filteredListChanges = combineLatest([
    this.listChanges,
    this.localFilteredListSubject.pipe(startWith(null)),
  ]).pipe(
    withLatestFrom(this.initialValueSubject),
    map(([[list, localFilteredList], initialValue]) => {
      const currentValue = this.baseControl.value;

      if (this.multiple && Array.isArray(currentValue)) {
        // Multi-value handling
        const missingValues = currentValue.filter(
          (value) => !list.some((item) => this.compareWith(item, value)),
        );
        if (missingValues.length > 0) {
          return [...missingValues, ...(localFilteredList || list)];
        }
      } else {
        // Single value handling
        if (localFilteredList === null) {
          if (currentValue) {
            return [currentValue, ...list];
          } else if (initialValue) {
            return [initialValue, ...list];
          }
          return list;
        }
      }

      return localFilteredList === null ? list : localFilteredList;
    }),
    shareReplay(1),
  );

  @ViewChild(MatSelect) public readonly matSelect?: MatSelect;

  @Input() public displayKey!: NestedKeysOfString<T>;

  /** Whether or not the dropdown should display a select all option. */
  @Input() public set selectAllEnabled(value: BooleanInput) {
    this.#selectAllEnabled = coerceBooleanProperty(value);
  }
  public get selectAllEnabled(): boolean {
    return this.#selectAllEnabled;
  }
  #selectAllEnabled = false;

  /** The label to display for the clear button. */
  @Input() public clearLabel?: string;

  @Input() public set isClearable(value: BooleanInput) {
    this.#isClearable = coerceBooleanProperty(value);
  }
  public get isClearable(): boolean {
    return this.#isClearable;
  }
  #isClearable = false;

  /**
   * Emit an event when the input dropdown was cleared with the internal clear
   * function and button.
   */
  @Output() public readonly cleared = new EventEmitter<void>();

  protected get displayNestedSearchInput(): boolean {
    const isPanelOpen = this.matSelect?.panelOpen ?? false;
    return (
      isPanelOpen &&
      (this.baseControl.value === null ||
        (Array.isArray(this.baseControl.value) &&
          this.baseControl.value.length === 0))
    );
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    if (!this.baseControl) {
      throw new Error('The base control is required.');
    }

    this.initialValueSubject.next(this.baseControl.value);

    // If `multiple` is `false` and `max` attribute is set, throw an error.
    if (!this.multiple && this.max !== undefined) {
      throw new Error(
        'The `max` attribute can only be set when `multiple` is `true`.',
      );
    }

    // Subscribe to the value changes of the form control.
    this.baseControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      // Update the error state when the selection limit is exceeded.
      if (this.isOverSelectionLimit) {
        this.baseControl.setErrors(
          {
            ...this.baseControl.errors,
            maxSelection: true,
          },
          { emitEvent: false },
        );
      }

      // Ensure that the input element is properly rendered before focusing.
      this.changeDetectorRef.detectChanges();

      // Only focus if the searchInput is available
      if (this.searchInput && this.searchInput.nativeElement) {
        // Focus the search input when the dropdown value is updated.
        this.searchInput.nativeElement.focus();
      }
    });
  }

  public ngAfterViewInit(): void {
    if (!this.matSelect) {
      throw new Error('The mat-select element is required.');
    }

    // React to the Angular Material select dropdown being opened or closed.
    this.matSelect.openedChange
      .pipe(untilDestroyed(this))
      .subscribe((isOpen) => {
        if (isOpen) {
          // Ensure the backdrop is not present:
          // `div.cdk-overlay-container > div.cdk-overlay-backdrop`.
          // so it doesn't interfere with the search input.
          const overlayContainerElement =
            this.overlayContainer.getContainerElement();
          let backdrop: HTMLElement | null = null;
          const childrenArray = Array.from(overlayContainerElement.children);
          for (const child of childrenArray) {
            if (
              (child as HTMLElement).classList.contains('cdk-overlay-backdrop')
            ) {
              backdrop = child as HTMLElement;
              break;
            }
          }
          backdrop?.remove();

          // Add click event listener on document when dropdown is open.
          this.document.addEventListener(
            'click',
            this.handleClickOutside.bind(this),
          );

          if (this.searchInput && this.searchInput.nativeElement) {
            // Focus the search input when the dropdown is opened.
            this.searchInput.nativeElement.focus();
          }
        } else {
          // Remove the event listener when the dropdown is closed.
          this.document.removeEventListener(
            'click',
            this.handleClickOutside.bind(this),
          );
        }
      });
  }

  public ngOnDestroy(): void {
    // Ensure the event listener is removed if the component is destroyed.
    this.document.removeEventListener('click', this.handleClickOutside);
  }

  /**
   * Clear the dropdown selection and search input value.
   */
  protected clear(): void {
    if (this.isClearable && this.matSelect) {
      if (Array.isArray(this.matSelect.selected)) {
        this.matSelect.selected.forEach((selected) => selected.deselect(false));
      } else {
        this.matSelect.selected.deselect(false);
      }
      // Reset the form control value.
      this.baseControl.setValue(null);
      // Reset the filter.
      this.filter('');
      // Clear the search value.
      this.searchValue = '';
      // Emit the cleared event.
      this.cleared.emit();
    }
  }

  /**
   * Function to compare the option values with the selected values. The first argument
   * is a value from an option. The second is a value from the selection. A boolean
   * should be returned.
   */
  @Input() public compareWith: MatSelect['compareWith'] = (
    optionValue,
    selectedValue,
  ) => optionValue === selectedValue;

  /**
   * Filter the list of items based on the provided value.
   *
   * @param value The value to filter the list by.
   */
  protected async filter(value: string): Promise<void> {
    if (!this.isLocalFilter) {
      this.filterValueChange.emit(value || null);
      return;
    }

    const currentList: readonly T[] = await firstValueFrom(this.listChanges);
    const localFilteredList = arrayFilterAll(currentList, value, {
      searchNestedKeys: true,
    });

    this.localFilteredListSubject.next(localFilteredList);
  }

  /**
   * Gets the display value for the provided item.
   *
   * @param item The item to get the display value for.
   * @returns The display value for the provided item.
   */
  protected getDisplayValue(item: T): string {
    return (getNestedObjectData<T>(item, this.displayKey) || '—').toString();
  }

  /**
   * Handle the panel toggle opened/closed event.
   */
  protected onPanelToggle(): void {
    const panelOpen = this.matSelect?.panelOpen ?? false;
    if (panelOpen) {
      // Focus the search input when the dropdown is opened.
      this.searchInput.nativeElement.focus();
    } else {
      // Reset the filter when the dropdown is closed.
      this.filter('');
      // Clear the search value when the dropdown is closed.
      this.searchValue = '';
    }
  }

  /**
   * Toggles the selection of all options in the dropdown. If all options are
   * already selected, they will be deselected. If any options are not selected,
   * they will be selected.
   */
  protected toggleAll(): void {
    if (this.#selectAllEnabled && this.matSelect) {
      // Get all MatOption instances (including from option groups if they exist)
      const allOptions = this.matSelect.options.toArray();

      // If all options are already selected, deselect them
      if (allOptions.every((option) => option.selected)) {
        allOptions.forEach((option) => option.deselect());
        this.baseControl.setValue(null);
        return;
      }

      // Select each option
      allOptions.forEach((option) => option.select());

      // Update the form control with the selected values
      const selectedValues: T = allOptions.map((option) => option.value) as T;
      this.baseControl.setValue(selectedValues);
    }
  }

  /**
   * Handle the click event outside the dropdown to close it manually. We have
   * to do this manually since we disabled the CDK backdrop so we could access
   * the search input.
   *
   * @param event The click event that occurred.
   */
  private readonly handleClickOutside = (event: MouseEvent): void => {
    const overlayContainerElement = this.overlayContainer.getContainerElement();
    const isClickInsideDropdown = overlayContainerElement.contains(
      event.target as Node,
    );

    // Check if the click is inside the dropdown panel or the component itself
    const isClickInsideComponent = this.elementRef.nativeElement.contains(
      event.target as Node,
    );

    if (
      !isClickInsideDropdown &&
      !isClickInsideComponent &&
      this.matSelect?.panelOpen
    ) {
      // If the click is outside both the dropdown panel and the component, close the dropdown.
      this.matSelect.close();
    }
  };
}
