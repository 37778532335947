import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, combineLatest, map, startWith } from 'rxjs';
import { isNonEmptyString } from 'src/app/utilities';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
  MatAutocomplete,
} from '@angular/material/autocomplete';

import { CustomInputDirective } from 'src/app/components/forms/custom-input.directive';

/**
 * An input component that allows users to select items from an autocomplete
 * list.
 *
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 * @requires `list` attribute to be set.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-input-autocomplete[formControl][label][list]',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: {
        // Do not automatically activate the first option in the list.
        autoActiveFirstOption: false,
        // Do not auto-select the active option while navigating.
        autoSelectActiveOption: false,
        // Do not hide indicators for single selection.
        hideSingleSelectionIndicator: false,
        // Apply a custom class to the overlay panel.
        overlayPanelClass: ['alleva-autocomplete-panel'],
        // Do not require the user to select an option (we'll handle that in
        // our component)
        requireSelection: false,
      },
    },
  ],
})
export class InputAutocompleteComponent<T>
  extends CustomInputDirective<T>
  implements OnInit
{
  public constructor(
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
  ) {
    super(ngControl, changeDetectorRef, elementRef);
  }

  /**
   * The list of items to display in the autocomplete list.
   *
   * @required This is a required input.
   */
  @Input() public set list(values: readonly T[] | null | undefined) {
    if (!values) {
      return;
    }
    this.listSubject.next(values);
  }
  private readonly listSubject = new ReplaySubject<readonly T[]>(1);

  private readonly filteredListSubject = new ReplaySubject<readonly T[]>(1);
  protected readonly filteredListChanges =
    this.filteredListSubject.asObservable();

  /**
   * Emits the search value when it changes here. This is useful for updating
   * the `list` data dynamically via the parent component.
   */
  @Output() public readonly searchValueChange = new EventEmitter<
    string | null
  >();

  /**
   * Function that maps an option's control value to its display value in the
   * trigger.
   */
  @Input() public displayWith: MatAutocomplete['displayWith'] = null;

  public override ngOnInit(): void {
    super.ngOnInit();

    if (!this.baseControl) {
      throw new Error('The `formControl` attribute is required.');
    }

    combineLatest([
      this.listSubject.asObservable(),
      this.baseControl.valueChanges.pipe(startWith(null)),
    ])
      .pipe(
        map(([list, searchValue]): FilteredListUpdateValue<T> => {
          if (!searchValue) {
            // Nothing to search for, return the full list provided.
            return { filteredList: list, searchValue: null };
          }

          if (isNonEmptyString(searchValue)) {
            // A search value was provided, filter the list by the search
            // value in conjunction with `displayWith` function or a plain
            // string depending on its existence.
            const filteredList = list.filter((listItem) =>
              this.displayWith
                ? this.displayWith(listItem)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
                : String(listItem)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()),
            );
            return { filteredList, searchValue };
          }

          return {
            filteredList: list.filter(
              (listItem) =>
                JSON.stringify(listItem) === JSON.stringify(searchValue),
            ),
            searchValue: null,
          };
        }),
        untilDestroyed(this),
      )
      .subscribe(({ filteredList, searchValue }) => {
        // Update the filtered list for the autocomplete.
        this.filteredListSubject.next(filteredList);

        // Emit the search value for the parent component to optionally update
        // the `list` data dynamically.
        this.searchValueChange.emit(searchValue);

        const isStringMatch =
          filteredList.length > 0 &&
          typeof searchValue === 'string' &&
          typeof filteredList[0] === 'string';

        const isObjectMatch =
          filteredList.length > 0 &&
          typeof searchValue === 'object' &&
          typeof filteredList[0] === 'object';

        if (isStringMatch || isObjectMatch) {
          // The selected value matches the __type__ of the __T__ list items,
          // everything is good. Nullify all existing errors and return.
          if (this.baseControl.hasError('mustSelect')) {
            this.baseControl.setErrors({ mustSelect: false });
            this.changeDetectorRef.detectChanges();
          }
        } else {
          // The selected value does not match the __type__ of the __T__ list
          // items, set the `mustSelect` error. This will occur when the user
          // types in a value that does not match any of the list items or if
          // the user does not select an item from the list.
          this.baseControl.setErrors({ mustSelect: true });
        }
        // Manually trigger change detection to update the UI.
        this.changeDetectorRef.detectChanges();
      });
  }
}

interface FilteredListUpdateValue<T> {
  filteredList: readonly T[];
  searchValue: string | null;
}
