import { FacilityApi } from 'api/models';
import {
  Observable,
  ReplaySubject,
  catchError,
  distinctUntilChanged,
  firstValueFrom,
  map,
  of,
  shareReplay,
} from 'rxjs';
import { Facility } from 'src/app/models';
import { parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FacilityBase } from 'src/app/models/facility/facility.model';

import { AlertService } from 'src/app/services/root/alert.service';

@Injectable({ providedIn: 'root' })
export class FacilityService {
  public constructor(
    private readonly alertService: AlertService,
    private readonly httpClient: HttpClient,
  ) {}

  public initialized = false;

  public set currentFacility(facility: Facility) {
    this.initialized = true;
    this.currentFacilityId = facility.id;
    this.currentFacilitySubject.next(facility);
  }
  private readonly currentFacilitySubject = new ReplaySubject<Facility>(1);
  public readonly currentFacilityChanges = this.currentFacilitySubject.pipe(
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  private currentFacilityId: Facility['id'] | null = null;

  /**
   * Fetch and return data for a single facility.
   *
   * @param facilityId The id of the facility to fetch.
   * @returns The facility on success, undefined on error.
   */
  private get(facilityId: Facility['id']): Observable<FacilityResponse> {
    return this.httpClient
      .get<FacilityApi>(`${config.api.url}/facilities/${facilityId}`, {
        observe: 'response',
      })
      .pipe(
        map(({ body, status }) => ({
          facility: body ? Facility.deserialize(body) : null,
          status,
        })),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            return of({ facility: null, status: error.status });
          }
          // Default to 404 for all other cases.
          return of({ facility: null, status: 404 });
        }),
      );
  }

  /**
   * Fetch and return base data for all facilities.
   *
   * @returns All facilities on success, undefined on error.
   */
  public getBaseList(
    requestParameters?: readonly FacilityRequestParam[],
  ): Observable<readonly FacilityBase[] | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<readonly FacilityApi[] | undefined>(`${config.api.url}/facilities`, {
        params,
      })
      .pipe(
        map((response) =>
          response ? FacilityBase.deserializeList(response) : undefined,
        ),
        catchError(() => of(undefined)),
      );
  }

  /**
   * Set the current facility by id.
   *
   * @param facilityId The id of the facility to set.
   * @returns True if the facility was set successfully, false otherwise.
   */
  public async setFacilityById(facilityId: Facility['id']): Promise<boolean> {
    if (this.currentFacilityId === facilityId) {
      // The requested facility is already the active facility. No need to
      // fetch the facility data again, return the current facility.
      return true;
    }

    // Fetch the facility data and set the current facility.
    const response = await firstValueFrom(this.get(facilityId));
    if (response.facility === null) {
      switch (response.status) {
        case 403: {
          this.alertService.error({
            message:
              'You do not have permission to access this facility. Please contact your Administrator for assistance.',
          });
          break;
        }
        case 404:
        default: {
          this.alertService.error({
            message:
              'An error occurred while accessing the facility. Please contact your Administrator if this issue persists.',
          });
          break;
        }
      }
      return false;
    }

    this.currentFacility = response.facility;
    return true;
  }
}

interface FacilityResponse {
  facility: Facility | null;
  status: number;
}

/** Request parameter interface for use with the Facility API. */
export interface FacilityRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'active' | 'order';
  /** The value to use for the query parameter. */
  value: boolean | keyof FacilityBase;
}
